import RestService from '../services/RestService';

export const copyTemplate = (template, data) =>
  RestService.request('POST', `projects/copy?template=${template}`, data);

export const deleteProject = (projectId) =>
  RestService.request('DELETE', `projects/${projectId}`);

// Used when window is closed or reloaded
export const deleteProjectWithBeacon = (projectId) =>
  navigator.sendBeacon(
    `${process.env.REACT_APP_BACK_URL}delete_project/${projectId}`
  );

export const getCost = (projectId) =>
  RestService.request('GET', `projects/cost/${projectId}`);

export const updateProduct = (productId, data) =>
  RestService.request('PUT', `productDefinitions/${productId}`, data);

export const updateMultipleProducts = (data) =>
  RestService.request('PUT', `productDefinition/updateMultiple`, data);

export const updateSide = (productId, data) =>
  RestService.request('PUT', `sides/${productId}`, data);

export const updateMultipleSides = (data) =>
  RestService.request('PUT', `side/updateMultiple`, data);

export const getPublicIp = () => RestService.request('GET', 'public_ip');

export const getParameterChoices = (sideId, parameterName, door_type, centinel = false) =>
  RestService.request(
    'GET',
    `productDefinitions/side/${sideId}/choices/${door_type}/${centinel ? "" : "parameters." }${parameterName}?lang=fi`
  );

export const getSideInfo = (sideId) => 
  RestService.request('GET',`side/${sideId}`);

export const getProductInfo = (productId) => 
  RestService.request('GET',`productDefinitions/${productId}`);

export const getDrawingList = (projectId) => 
  RestService.request('GET',`drawings/project/${projectId}`);

export const getProductList = (drawingId) => 
  RestService.request('GET',`drawings/${drawingId}`);