import "./colors_box.css";
import chroma from "chroma-js";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { scrollToBottom } from "../../utils";

const color = chroma("black");
const colorSelected = color.alpha(0.3).css();
const colorFocused = color.alpha(0.2).css();
const colorActive = color.alpha(0.3).css();

const customStyles = {
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    background: isDisabled
      ? null
      : isSelected
      ? colorSelected
      : isFocused
      ? colorFocused
      : null,
    cursor: "pointer",
    ":active": {
      ...provided[":active"],
      background: !isDisabled && (isSelected ? color : colorActive),
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "0",
    cursor: "pointer",
    border: "none",
  }),
  indicatorSeparator: () => ({}),
};

const getOptionIndex = (options, value) => {
  const arrayLength = options.length;
  for (let i = 0; i < arrayLength; i += 1) {
    // eslint-disable-next-line eqeqeq
    if (options[i].value == value) return i;
  }
  return null;
};

const TextBox = ({ options, sendRequest, selectedOption, isAtTheEnd, placeholder="Valitse...", name }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const optionIndex = getOptionIndex(options, selectedOption);
  useEffect(() => {
    if (isAtTheEnd && menuOpened) {
      scrollToBottom();
    }
  }, [menuOpened, isAtTheEnd]);
  return (
    <Select
      name={name}
      options={options}
      value={options[optionIndex]}
      isSearchable={false}
      styles={customStyles}
      onChange={(element) => {
        sendRequest(element.value, name);
      }}
      placeholder={placeholder}
      onMenuOpen={() => setMenuOpened(true)}
      onMenuClose={() => setMenuOpened(false)}
    />
  );
};

TextBox.defaultProps = {
  isAtTheEnd: false,
};

export default TextBox;
