/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../../providers/ProjectDataProvider/use';
/* import {
  MIN_GLASS_WIDTH,
  MAX_GLASS_WDITH,
} from '../../constants/validations'; */

const DividerChecker = ({ name, label, isShown = false, sendRequest }) => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');
  const [errMsg, setErrMsg] = useState('');
    
  useEffect(() => {
    if (projectData.product.parameters.split_height === null) return;
    setErrMsg('');
  }, [projectData.product.parameters.split_height]);
  
  const handleOnChange = (event) => {
    sendRequest(event.target.checked, name); 
  }

  return( 
    <div className={`split_divider ${isShown ? '' : 'hidden'}`}>
      <label 
        htmlFor={label}
        className="text-color1"
      >
        <input 
          name={name} 
          type="checkbox" 
          onChange={handleOnChange} 
        />
        {t('page_2.split_divider_check')}
      </label>
      <div className="feedback-message" key={`errors_${name}`}>{errMsg}</div>
    </div>
  );
};

export default DividerChecker;
