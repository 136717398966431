import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useProjectData from '../providers/ProjectDataProvider/use';

const useOptions = () => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');

  const leftLockOptions = useMemo(
    () => {
      const result = [{
        value: '',
        label: t('page_2.no_lock'),
      }];
      const locks = projectData.locksOptionsLeft;
      locks.map((lock) => 
        result.push(
          {
            value: lock.value,
            label: `${lock.label} | ${lock.code}`,
          })
      );
      return result;
    },
    [projectData.locksOptionsLeft, t]
  )

  const middleLockOptions = useMemo(
    () => {
      const result = [{
        value: '',
        label: t('page_2.no_lock'),
      }];
      const locks = projectData.locksOptionsMiddle;
      locks.map((lock) => 
        {
          if(lock.label !== 'Vetonuppi') {
            result.push(
              {
                value: lock.value,
                label: `${lock.label} | ${lock.code}`,
              });
          }
          return result;
        }
      );
      return result;
    },
    [projectData.locksOptionsMiddle, t]
  );

  const rightLockOptions = useMemo(
    () => {
      const result = [{
        value: '',
        label: t('page_2.no_lock'),
      }];
      const locks = projectData.locksOptionsRight;
      locks.map((lock) => 
        result.push(
          {
            value: lock.value,
            label: `${lock.label} | ${lock.code}`,
          })
      );
      return result;
    },
    [projectData.locksOptionsRight, t]
  );

  /* VEDIN OPTIONS */
  const leftVedinOptions = useMemo(
    () => {
      const result = [{
        value: '',
        label: t('page_2.no_vedin'),
      }];
      const vedins = projectData.vedinOptionsLeft;
      vedins.map((vedin) => 
        result.push(
          {
            value: vedin.value,
            label: `${vedin.label} | ${vedin.code}`,
          })
      );
      return result;
    },
    [projectData.vedinOptionsLeft, t]
  )

  const middleVedinOptions = useMemo(
    () => {
      const result = [{
        value: '',
        label: t('page_2.no_vedin'),
      }];
      const vedins = projectData.vedinOptionsMiddle;
      vedins.map((vedin) => 
        {
          result.push(
            {
              value: vedin.value,
              label: `${vedin.label} | ${vedin.code}`,
            });
          return result;
        }
      );
      return result;
    },
    [projectData.vedinOptionsMiddle, t]
  );

  const rightVedinOptions = useMemo(
    () => {
      const result = [{
        value: '',
        label: t('page_2.no_vedin'),
      }];
      const vedins = projectData.vedinOptionsRight;
      vedins.map((vedin) => 
        result.push(
          {
            value: vedin.value,
            label: `${vedin.label} | ${vedin.code}`,
          })
      );
      return result;
    },
    [projectData.vedinOptionsRight, t]
  );
  /* END OF VEDIN OPTIONS */

  const colorsList = useMemo(
    () =>
      projectData.colorOptions.map((color) => ({
        value: color.value,
        label: (
          <div className="color-container">
            <div
              className="color-box"
              style={{
                background: `#${color.hex}`,
                verticalAlign: 'middle',
              }}
            />
            <div className="color-name">{color.label}</div>
          </div>
        ),
      })),
    [projectData.colorOptions]
  );

  return [leftLockOptions, middleLockOptions, rightLockOptions, leftVedinOptions, middleVedinOptions, rightVedinOptions, colorsList];
};

export default useOptions;
