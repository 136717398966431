/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import './parameters.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColorsBox from '../../components/SelectBox/ColorsBox';
import GlassOptions from '../../components/SelectBox/GlassOptions';
import TextBox from '../../components/SelectBox/TextBox';
import LeafField from './LeafField';
import WidthField from './WidthField';
import HeightField from './HeightField';
import DividerChecker from './DividerChecker';
import {
  productDefinitionQueryBody,
} from '../../utils';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import Constants from '../../constants/index';
// import Modal from '../../components/Modal';
import useOptions from '../../hooks/useOptions';
import useProjectData from '../../providers/ProjectDataProvider/use';

const Parameters = ({ goBack, makeUpdateRequest }) => {
  const { t } = useTranslation('common');
  
  const { projectData, dispatch } = useProjectData();
  const [
    leftLockOptions,
    middleLockOptions,
    rightLockOptions,
    leftVedinOptions,
    middleVedinOptions,
    rightVedinOptions,
    colorsList
  ] = useOptions();

  const isTemplateLoading = projectData.projectId === null || projectData.product === null;
  const showVedinSelection = projectData.currentTemplate === 2;
  const [hasHorizontalSplit, setHasHorizontalSplit] = useState(projectData.product.parameters.split_height !== null && projectData.product.parameters.split_height !== undefined);

  const [showMiddleLock, setShowMiddleLock] = useState(true);

  const sendRequest = (value, parameterName) => {
    let typeD = 'product';
    const payloadD = value;
    let parameters = null;
    let pathId = 0;
    const path = ['productDefinitions', 'sides'];
    const doorType = projectData.currentTemplate === 1 ? Constants.FRAMED_SLIDE_DOOR : Constants.FRAMELESS_SLIDE_DOOR;
    switch(parameterName) {
      case 'side_resize[width]':
        parameters = { width: value };
        typeD = 'system_width';
        pathId = 1;
        break;
      case 'side_resize[height]':
        parameters = { height: value };
        typeD = 'system_height';
        pathId = 1;
        break;
      case 'glass':
      case 'lower_glass':
      case 'split_height':
      case 'leavesLeft': 
      case 'leavesRight':
      case 'hole_color':
      case 'lock_left':
      case 'lock_middle':
      case 'lock_right':
      case 'handle_left':
      case 'handle_middle':
      case 'handle_right':
        parameters = { [parameterName]: value };
        break;
      case 'split_divider':
        setHasHorizontalSplit(value);
        if (value) {
          parameters = { 'split_height': projectData.systemHeight * 0.3 };
        } else {
          parameters = { 'split_height': 0 };
        } 
        break;
      default:
        typeD = '';
        break;
    }
    if (typeD.length > 0) {
      const data = pathId === 0 ? productDefinitionQueryBody(doorType, parameters, "parameters", parameterName === 'hole_color') : parameters;
      makeUpdateRequest(data, path[pathId], (pathId === 0 ? projectData.product.id : projectData.sideId));
      if(typeD !== 'product')
        dispatch({ type: typeD, payload: payloadD });
      else
        dispatch({ type: 'has_changed', payload: true });    
    }
  }

  const getInputValues = (nameParameter) => {
    switch(nameParameter) {
      case 'side_resize[width]':
        return projectData.systemWidth;
      case 'side_resize[height]':
        return projectData.systemHeight;
      case 'leavesLeft': 
        return projectData.product.parameters.leavesLeft;
      case 'leavesRight':
        return projectData.product.parameters.leavesRight
      case 'split_height':
        return projectData.product.parameters.split_height;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (projectData.product === null) return;
    const anyLeafIsZero = projectData.product.parameters.leavesLeft === 0 || projectData.product.parameters.leavesRight === 0;
    if (anyLeafIsZero && showMiddleLock) {
      sendRequest('', 'lock_middle');
      sendRequest('', 'handle_middle');
    }
    setShowMiddleLock(!anyLeafIsZero);
  }, [projectData.product]);

  return (
    <>
      <div id="parameters-container">
        {isTemplateLoading && <LoadingPlaceholder />}

        {!isTemplateLoading && (
          <>
            <h1 className="title">{hasHorizontalSplit ? t('page_2.upper_glass_color') : t('page_2.glass_color')}</h1>
            <GlassOptions
              name='glass'
              sendRequest={sendRequest}
              options={projectData.glassOptionsUpper}
              initialValue={projectData.product.parameters.glass}
              t={t}
            />
            <div className="parameters-horizontal-line" />
            {hasHorizontalSplit && (
            <>
              <h1 className="title">{t('page_2.lower_glass_color')}</h1>
              <GlassOptions
                name='lower_glass'
                sendRequest={sendRequest}
                options={projectData.glassOptionsLower}
                initialValue={projectData.product.parameters.lower_glass}
                t={t}
              />
              <div className="parameters-horizontal-line" />
            </>
            )}
          </>
        )}        
        {!isTemplateLoading && (
          <>
            <h1 className="title">{t('page_2.opening_dimensions')}</h1>
            <WidthField
              name="side_resize[width]"
              sendRequest={sendRequest}
              label={t('page_2.width')}
              initialValue={getInputValues}
              isDisabled={false}
              units="mm"
            />
            <HeightField
              name="side_resize[height]"
              sendRequest={sendRequest}
              label={t('page_2.height')}
              initialValue={getInputValues}
              isDisabled={false}
              units="mm"
            />
            <DividerChecker
              name="split_divider"
              label="split_divider"
              sendRequest={sendRequest}
              isShown={projectData.currentTemplate === 1}
            />
            {hasHorizontalSplit && (
            <>
              <HeightField
                name="split_height"
                sendRequest={sendRequest}
                label={t('page_2.split_height')}
                initialValue={getInputValues}
                isDisabled={false}
                units="mm"
              />
            </>
            )}
            <div className="parameters-horizontal-line" />
          </>
        )}

        {!isTemplateLoading && (
          <>
            <h1 className="title">{t('page_2.leafs')}</h1>
            <LeafField
              name="leavesLeft"
              sendRequest={sendRequest}
              label={t('page_2.left_leafs')}
              initialValue={getInputValues}
              isDisabled={false}
              units="kpl"
            />
            <LeafField
              name="leavesRight"
              sendRequest={sendRequest}
              label={t('page_2.right_leafs')}
              initialValue={getInputValues}
              isDisabled={false}
              units="kpl"
            />
          </>
        )}

        {!isTemplateLoading && (
          <>
            <h1 className="title">{t('page_2.profile_color')}</h1>
            <ColorsBox
              name="hole_color"
              sendRequest={sendRequest}
              color={projectData.product.color}
              colorOptions={colorsList}
              isAtTheEnd
            />
            <div className="parameters-horizontal-line" />
          </>
        )}
        {(!isTemplateLoading && showVedinSelection) && (
          <>
            <h1 className="title">{t('page_2.vedin')}</h1>
            <div className='group-input-25'>
              <label className="text-color1">{t('page_2.left_vedin')}</label>
              <TextBox
                name="handle_left"
                options={leftVedinOptions}
                sendRequest={sendRequest}
                selectedOption={projectData.product.parameters.handle_left}
                placeholder={t('page_2.no_vedin')}
                isAtTheEnd
              />             
            </div>
            {showMiddleLock && (
              <div className='group-input-25'>
                <label className="text-color1">{t('page_2.middle_vedin')}</label>
                <TextBox
                  name="handle_middle"
                  options={middleVedinOptions}
                  sendRequest={sendRequest}
                  selectedOption={projectData.product.parameters.handle_middle}
                  placeholder={t('page_2.no_vedin')}
                  isAtTheEnd
                />              
              </div>
            )}
            <div className='group-input-25'>
              <label className="text-color1">{t('page_2.right_lock')}</label>
              <TextBox
                name="handle_right"
                options={rightVedinOptions}
                sendRequest={sendRequest}
                selectedOption={projectData.product.parameters.handle_right}
                placeholder={t('page_2.no_vedin')}
                isAtTheEnd
              />              
            </div>         
            <div className="parameters-horizontal-line" />
          </>
        )}  
        {!isTemplateLoading && (
          <>
            <h1 className="title">{t('page_2.lock')}</h1>
            <div className='group-input-25'>
              <label className="text-color1">{t('page_2.left_lock')}</label>
              <TextBox
                name="lock_left"
                options={leftLockOptions}
                sendRequest={sendRequest}
                selectedOption={projectData.product.parameters.lock_left}
                placeholder={t('page_2.no_lock')}
                isAtTheEnd
              />             
            </div>
            {showMiddleLock && (
              <div className='group-input-25'>
                <label className="text-color1">{t('page_2.middle_lock')}</label>
                <TextBox
                  name="lock_middle"
                  options={middleLockOptions}
                  sendRequest={sendRequest}
                  selectedOption={projectData.product.parameters.lock_middle}
                  placeholder={t('page_2.no_lock')}
                  isAtTheEnd
                />              
              </div>
            )}
            <div className='group-input-25'>
              <label className="text-color1">{t('page_2.right_lock')}</label>
              <TextBox
                name="lock_right"
                options={rightLockOptions}
                sendRequest={sendRequest}
                selectedOption={projectData.product.parameters.lock_right}
                placeholder={t('page_2.no_lock')}
                isAtTheEnd
              />              
            </div>         
            <div className="parameters-horizontal-line last-parameter" />
          </>
        )}        
      </div>
      <div
        onClick={goBack}
        className="button color1 text-center next-button desktop-button"
        role="button"
        tabIndex={0}
      >
        <div className="button-triangle--back--desktop" />
        <div>{t('back_button')}</div>
      </div>
    </>
  );
};

export default Parameters;
