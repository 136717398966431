/* eslint-disable react/no-array-index-key */
import './number_input.css';
import React, { useEffect, useState } from 'react';
// import withForm from '../../HOCs/withForm';

// sendRequest,
const NumberInput = ({
  name,
  label,
  value,
  errors,
  isDisabled,
  handleOnChange,
  units,
}) => {
  const [inputRef] = useState(React.createRef());
  const hasErrors = errors ? errors.length > 0 : false;
  // const [valor] = useState(value); 

  const renderErrors = () => {
    if (!hasErrors) {
      return null;
    }

    // eslint-disable-next-line no-unused-vars
    const errorsResult = errors.map((errMsg) => (
      <div className="feedback-message" key={`errors_${name}`}>{errMsg}</div>
    ));
    return errorsResult;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleOnChange(inputRef.current.value);
    }
  };

  useEffect(() => {
    if (inputRef.current.value !== value(name)) {
      inputRef.current.value = value(name);
    }
  }, [inputRef, value(name)]);

  return (
    <>
      <div className={`group-input ${hasErrors ? 'invalid-group-input' : ''}`}>
        <label className="text-color1">{label}</label>
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
          <input
            ref={inputRef}
            className="custom-input"
            type="number"
            name={name}
            disabled={isDisabled}
            onKeyPress={handleKeyPress}    
            onBlur={()=>handleOnChange(inputRef.current.value)}
            defaultValue={value(name)}      
          />
          <div className="mm">{units}</div>
        </div>
      </div>
      {renderErrors()}
    </>
  );
};

NumberInput.defaultProps = {
  isDisabled: false,
};

// const NumberInputWithForm = withForm(NumberInput);

export default NumberInput;
