const reducer = (state, action) => {
  switch (action.type) {
    case 'project_name':
      return { ...state, projectName: action.payload };
    case 'share_id':
      return { ...state, shareId: action.payload };
    case 'project_id':
      return { ...state, projectId: action.payload };
    case 'drawing_id':
      return { ...state, drawingId: action.payload };
    case 'system_height':
      return { ...state, systemHeight: action.payload };
    case 'system_width':
      return { ...state, systemWidth: action.payload };
    case 'loading_cost':
      return { ...state, isLoadingCost: action.payload };
    case 'loading_model':
      return { ...state, isLoadingModel: action.payload };
    case 'project_cost':
      return { ...state, projectCost: action.payload };
    case 'product':
      return { ...state, product: action.payload };
    case 'side_id':
      return { ...state, sideId: action.payload };
    case 'current_section':
      return { ...state, currentSection: action.payload };
    case 'current_template':
      return { ...state, currentTemplate: action.payload };
    case 'glass':
      return { ...state, glassOptionsUpper: action.payload };
    case 'lower_glass':
      return { ...state, glassOptionsLower: action.payload };
    case 'lock_left':
      return { ...state, locksOptionsLeft: action.payload };  
    case 'lock_middle':
      return { ...state, locksOptionsMiddle: action.payload };
    case 'lock_right':
      return { ...state, locksOptionsRight: action.payload };
    case 'handle_left':
      return { ...state, vedinOptionsLeft: action.payload };  
    case 'handle_middle':
      return { ...state, vedinOptionsMiddle: action.payload };
    case 'handle_right':
      return { ...state, vedinOptionsRight: action.payload };
    case 'hole_color':
      return { ...state, colorOptions: action.payload };
    case 'has_changed':
      return { ...state, hasChanged: action.payload };
    case 'has_loaded_options':
      return { ...state, hasLoadedOptions: action.payload };
    case 'control_3d':
      return { ...state, control3D: action.payload };
    case 'iframe_width':
      return { ...state, iframeWidth: action.payload };
    case 'iframe_height':
      return { ...state, iframeHeight: action.payload };
    case 'clear_project':
      return {
        ...state,
        product: null,
        projectId: null,
        drawingId: null,
        systemHeight: null,
        systemWidth: null,
        isLoadingModel: true,
        isLoadingCost: true,
        glassOptionsUpper: [],
        glassOptionsLower: [],
        locksOptionsLeft: [],
        locksOptionsMiddle: [],
        locksOptionsRight: [],
        vedinOptionsLeft: [],
        vedinOptionsMiddle: [],
        vedinOptionsRight: [],
        colorOptions:[],
        hasChanged: false,
        hasLoadedOptions: false,
        control3D: false,
      };
    case 'drawing':
      return {
        ...state,
        drawingId: action.payload.drawingId,
        systemWidth: action.payload.systemWidth,
        systemHeight: action.payload.systemHeight,
      };
    default:
      throw new Error(`Not a valid action: ${action.type}`);
  }
};

export default reducer;
