/* eslint-disable class-methods-use-this */
import { getProductInfo, getSideInfo, getDrawingList, getProductList } from '../api';

class ProjectService {
  getSystemWidth(sides) {
    let systemWidth = 0;
    sides.forEach((side) => {
      systemWidth += side.width;
    });

    return systemWidth;
  }

  async requestSideInfo(sideId) {
    try {
      const dataDrawing = await getSideInfo(sideId)
      .then((data) => {
          const { result } = data
          return result;
        });
      return dataDrawing;
    } catch (dataDrawing_1) {
      return 'None';
    }
  }

  /* return the product info */
  async requestProductInfo(productId) {
    try {
      return await getProductInfo(productId);
    } catch (productInfo_1) {
      return 'Error at getProductInfo';
    }
  }

  /* return drawings info */
  async requestDrawingList(projectId) {
    try {
      return await getDrawingList(projectId);
    } catch (drawingList) {
      return 'Error at getDrawingList';
    }
  }

  /* return Product List given drawingId */
  async requestProductList(drawingId) {
    try {
      return await getProductList(drawingId);
    } catch (productList) {
      return 'Error at getProductList';
    }
  }
  
}

export default new ProjectService();
