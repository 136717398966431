/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import useProjectData from '../../providers/ProjectDataProvider/use';
import {
  MIN_GLASS_HEIGHT,
  MAX_GLASS_HEIGHT,
} from '../../constants/validations'; 

const HeightField = ({ name, label, initialValue, units, sendRequest }) => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');
  const [errorList, setErrorList] = useState([]);
  const validations = {
    'side_resize[height]': { min : (projectData.product.parameters.split_height !== null ? (projectData.product.parameters.split_height + MIN_GLASS_HEIGHT) : MIN_GLASS_HEIGHT), max : MAX_GLASS_HEIGHT},
    'split_height': { min : MIN_GLASS_HEIGHT, max : (projectData.systemHeight - MIN_GLASS_HEIGHT)}
  };
    
  const validate = (newValue) => {
    if (newValue < validations[name].min) {
      setErrorList([t('warnings.less_than', { number: validations[name].min, units })]);
      return false;
    }
    if (newValue > validations[name].max) {
      setErrorList([t('warnings.more_than', { number: validations[name].max, units })]);
      return false;
    }
    setErrorList([]);
    return true;
  };

  const handleOnChange = (newValue) => {
    if(validate(newValue)) {
      sendRequest(newValue, name);
    }      
  };

  return(
    <NumberInput
      name={name}
      label={label}
      value={initialValue}
      errors={errorList}
      handleOnChange={handleOnChange}
      units={units}
    />
  );
};

export default HeightField;
