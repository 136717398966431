/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-return */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable func-names */
import './styles/app.css';
import './styles/lasitek.css';
import './styles/loading.css';
import './styles/parameters.css';
import './styles/header.css';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { isEmpty } from 'lodash';
import RestService from './services/RestService';
import Section from './views/Section';
import CieloModel from './views/CieloModel';
import Header from './views/Header';
import Requests from './Requests';
import useProjectData from './providers/ProjectDataProvider/use';
import { getCost } from './api';
import sendDataToParent from './postMessage';

const requestQueue = [];

const App = () => {
  const ref = useRef(null);
  const { projectData, dispatch } = useProjectData();
  const { t } = useTranslation('common');
  const checkModelLoadingState = () => {
    if (Requests.counter === 0) {
      dispatch({ type: 'loading_model', payload: false });
      makeCostRequest(projectData.projectId);
    }
  };

  const setSection = (section) => {
    if(projectData.hasLoadedOptions)
      dispatch({ type: 'current_section', payload: section });
  };

  const setCurrentTemplate = (template) => {
    dispatch({ type: 'current_template', payload: template });
  };

  const checkCostLoadingState = () => {
    if (Requests.cost === 0 && Requests.counter === 0) {
      dispatch({ type: 'loading_cost', payload: false });
    }
  };

  const makeCostRequest = (projectId) => {
    Requests.cost += 1;
    getCost(projectId)
      .then((data) => {
        dispatch({
          type: 'project_cost',
          payload: parseFloat(data.priceWithVat),
        });
        Requests.cost -= 1;
        checkCostLoadingState();
      })
      .catch((error) => {
        console.error(error);
        Requests.cost -= 1;
        checkCostLoadingState();
      });
  };

  const checkRequestsQueue = () => {
    if (!isEmpty(requestQueue)) {
      const { data, path } = requestQueue.shift();
      makeUpdateRequestCall(data, path);
    }
  };

  const makeUpdateRequest = (data, apiPath, id = null) => {
    const path = id !== null ? `${apiPath}/${id}` : apiPath;
    if (Requests.counter === 0) {
      makeUpdateRequestCall(data, path, id);
    } else {
      requestQueue.push({
        data,
        path,
      });
    }
  };

  const makeUpdateRequestCall = (data, apiPath) => {
    Requests.counter += 1;
    dispatch({ type: 'loading_model', payload: true });
    dispatch({ type: 'loading_cost', payload: true });

    return RestService.request('PUT', apiPath, data)
      .then(() => {
        Requests.counter -= 1;
        checkModelLoadingState();
        checkRequestsQueue();
      })
      .catch((error) => {
        console.error(error);
        Requests.counter -= 1;
        checkModelLoadingState();
        checkRequestsQueue();
      });
  };

  const sendDataToParentWindow = (reload=false) => {
    let split_height = 0;
    if (projectData.currentTemplate === 1) {
      if (projectData.product.parameters.split_height !== null) {
        split_height = projectData.product.parameters.split_height;
      }
    }
    const data = {
      id: projectData.projectId,
      cost: projectData.projectCost,
      height: projectData.systemHeight,
      width: projectData.systemWidth,
      split_height
    };
    console.log(data);
    sendDataToParent(data);
    if (reload) {
      window.location.reload();
    }
  };

  useEffect(() => {
    // template selection shown in the first page
    document.getElementById('btn-menu').checked = true;
    if (projectData.projectId === null) {
      dispatch({ type: 'loading_model', payload: true });
      dispatch({ type: 'loading_cost', payload: true });
    } else {
      makeCostRequest(projectData.projectId);
    }
  }, [projectData.projectId]);

  useEffect(() => {
    if(projectData.iframeWidth === 0){
      dispatch({ type: 'iframe_width', payload: ref.current.offsetWidth });
      dispatch({ type: 'iframe_height', payload: ref.current.offsetHeight });
    }
    const handleWindowResize = () => {
      dispatch({ type: 'iframe_width', payload: ref.current.offsetWidth });
      dispatch({ type: 'iframe_height', payload: ref.current.offsetHeight });
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className="app-container">
        <Header currentSection={projectData.currentSection} />
        <div className="horizontal-line" />
        <input type="checkbox" id="btn-menu" />

        <div id="main-content">
          {projectData.currentSection !== 1 && (
            <label className="btn-logo" htmlFor="btn-menu">
              <div
                id="show-hide-button"
                data-edit-text="Palaa valintoihin"
                data-see3d-text="Lasituksesi esikatselu"
              >
                <img
                  id="icon-3d"
                  src="/images/Cielo_yellow_3D_icon.png"
                  alt="cielo_icon"
                  height="45px"
                  width="45px"
                />
                <div id="wrap-arrow">
                  <div id="arrow" />
                </div>
              </div>
            </label>
          )}
          <section className="parameters">
            <div className="form-container">
              <Section
                currentSection={projectData.currentSection}
                setSection={setSection}
                makeUpdateRequest={makeUpdateRequest}
                setCurrentTemplate={setCurrentTemplate}
              />
            </div>
          </section>
          <div 
            id="model-container"
            ref={ref}
          >
            <div id="hide-control" className={projectData.control3D ? 'hidden' : ''} />
            <CieloModel
              sharedLinkCode={projectData.shareId}
              loading={projectData.isLoadingModel}
            />
          </div>
          <div className='clean' />
        </div>
        {projectData.currentSection === 1 && (
          <div
            onClick={() => {
              document.getElementById('btn-menu').checked = true;
              setSection(2);
            }}
            className={`button color1 text-center next-button mobile-button ${!projectData.hasLoadedOptions ? 'disabled' : ''}`}
            role="button"
            tabIndex={0}
          >
            <div>{t('next_button')}</div>
            <div className="button-triangle--next" />
          </div>
        )}
        {projectData.currentSection === 2 && (
          <div className="mobile-button floating-button">
            <div
              className="back-button"
              onClick={() => {
                document.getElementById('btn-menu').checked = true;
                setSection(1);
              }}
              role="button"
              tabIndex={0}
            >
              <div className="button-triangle--back" />
            </div>
            <div className="cost-container">
              <div className="total">{t('page_2.total')}</div>
              <div className="bar">|</div>
              {!projectData.isLoadingCost && (
                <div className="cost">{Math.ceil(projectData.projectCost)}</div>
              )}
              {projectData.isLoadingCost && (
                <ReactLoading
                  type="spinningBubbles"
                  color="#FFFFFF"
                  height="20px"
                  width="20px"
                />
              )}
              <div className="eur">EUR</div>
            </div>
            <div
              className="shopping-cart"
              role="button"
              onClick={sendDataToParentWindow}
              tabIndex={0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#ffed00"
                className="bi bi-cart"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <div className="footer-cost">
          <div className="total-desktop">{`${t('page_2.total')}:`}</div>
          <div className="cost-desktop">
            {Math.ceil(projectData.projectCost)}
          </div>
        </div>
        <div
          className="add-to-cart"
          role="button"
          onClick={() => sendDataToParentWindow(true)}
          tabIndex={0}
        />
      </div>
    </>
  );
};

export default App;
