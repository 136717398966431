/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import useProjectData from '../../providers/ProjectDataProvider/use';
import {
  MIN_GLASS_WIDTH,
  MAX_GLASS_WDITH,
} from '../../constants/validations';

const WidthField = ({ name, label, initialValue, isDisabled, units, sendRequest }) => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');
  const [errorList, setErrorList] = useState([]);
    
  const validate = (newValue) => {
    if (newValue < MIN_GLASS_WIDTH) {
      setErrorList([t('warnings.less_than', { number: MIN_GLASS_WIDTH, units })]);
      return false;
    }
    if (newValue > MAX_GLASS_WDITH) {
      setErrorList([t('warnings.more_than', { number: MAX_GLASS_WDITH, units })]);
      return false;
    }
    setErrorList([]);
    return true;
  };
  
  const handleOnChange = (newValue) => {
    if(newValue !== projectData.systemWidth && validate(newValue))
      sendRequest(newValue, name); 
  }

  return( 
    <NumberInput
      name={name}
      label={label}
      isDisabled={isDisabled}
      value={initialValue}
      errors={errorList}
      handleOnChange={handleOnChange}
      units={units}
    />
  );
};

export default WidthField;
