import React from 'react';
import TemplateSelection from './TemplateSelection';
import Parameters from './Parameters';

const Section = ({
  currentSection,
  setSection,
  makeUpdateRequest,
  setCurrentTemplate,
}) => (
  <>
    {currentSection === 1 && (
      <TemplateSelection
        goNext={() => setSection(2)}
        setCurrentTemplate={setCurrentTemplate}
      />
    )}
    {currentSection === 2 && (
      <Parameters
        goBack={() => setSection(1)}
        makeUpdateRequest={makeUpdateRequest}
      />
    )}
  </>
);

export default Section;
