/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './template_selection.css';
import Templates from '../../data/Templates';
import useProjectData from '../../providers/ProjectDataProvider/use';

const Page1 = ({ goNext, setCurrentTemplate }) => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');
  const images = useMemo(
    () =>
      Templates.map((template, index) => (
        <div key={`template_${template.id}`} className="template-container-det">
          <h4 key={`description_${template.id}`}>{template.description}</h4>
          <img
            key={index}
            className={`image-center ${
              index === projectData.currentTemplate - 1 ? 'selected-image' : ''
            }`}
            alt={template.name}
            src={template.image}
            onClick={() => setCurrentTemplate(index + 1)}
          />
        </div>
      )),
    [projectData.currentTemplate]
  );

  return (
    <>
      <div id="parameters-container">
        <div className="templates-container">{images}</div>
      </div>
      <div
        onClick={goNext}
        className={`button color1 text-center next-button desktop-button ${!projectData.hasLoadedOptions ? 'disabled' : ''}`}
        role="button"
        tabIndex={0}
      >
        <div>{t('next_button')}</div>
        <div className="button-triangle--next" />
      </div>
    </>
  );
};
export default Page1;
