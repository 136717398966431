/* eslint-disable react-hooks/exhaustive-deps */
import './colors_box.css';
import Select from 'react-select';
import chroma from 'chroma-js';
import React, { useEffect, useState } from 'react';
import { scrollToBottom } from '../../utils';

const color = chroma('black');
const colorSelected = color.alpha(0.3).css();
const colorFocused = color.alpha(0.2).css();
const colorActive = color.alpha(0.3).css();

const customStyles = {
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    background: isDisabled
      ? null
      : isSelected
      ? colorSelected
      : isFocused
      ? colorFocused
      : null,
    cursor: 'pointer',
    ':active': {
      ...provided[':active'],
      background: !isDisabled && (isSelected ? color : colorActive),
    },
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '0',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({}),
};

const getOptionIndex = (options, value) => {
  const arrayLength = options.length;
  for (let i = 0; i < arrayLength; i += 1) {
    // eslint-disable-next-line eqeqeq
    if (options[i].value == value) return i;
  }
  return null;
};

const ColorsBox = ({ sendRequest, color, colorOptions, isAtTheEnd, name }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const optionIndex = getOptionIndex(colorOptions, color);
  
  useEffect(() => {
    if (isAtTheEnd && menuOpened) {
      scrollToBottom();
    }
  }, [menuOpened, isAtTheEnd]);

  return (
    <div className="select-colors-box--container">
      <Select
        name={name}
        options={colorOptions}
        value={colorOptions[optionIndex]}
        styles={customStyles}
        isSearchable={false}
        onChange={(element) => {
          sendRequest(element.value, name);
        }}
        placeholder="Valitse..."
        onMenuOpen={() => setMenuOpened(true)}
        onMenuClose={() => setMenuOpened(false)}
      />
    </div>
  );
};

ColorsBox.defaultProps = {
  isAtTheEnd: false,
};

export default ColorsBox;
