import React from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ currentSection }) => {
  const { t } = useTranslation('common');
  return (
    <header className="header-container">
      {currentSection === 1 && (
        <div className="no-margin">
          <h1 className="title">{t('header.page_1.title')}</h1>
          <p className="subtitle">{t('header.page_1.subtitle')}</p>
        </div>
      )}
      {currentSection === 2 && (
        <div className="no-margin">
          <h1 className="title">{t('header.page_2.title')}</h1>
          <p className="subtitle">{t('header.page_2.subtitle')}</p>
        </div>
      )}
      <div className="no-margin">
        <h1 className="title">{t('ecommerce_configurator')}</h1>
        <p className="no-subtitle">{t('header.page_1.subtitle')}</p>
      </div>
    </header>
  );
};

export default Header;
