const Templates = [
  {
    id: 'LD_2',
    name: 'Template LD 2',
    image: './images/template2.svg',
    description: 'Pystypuitteellinen liukulasitus'
  },
  {
    id: 'LD_1',
    name: 'Template LD 1',
    image: './images/template1.svg',
    description: 'Pystypuitteeton liukulasitus'
  },
];

export default Templates;
