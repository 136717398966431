const initialState = {
  projectName: null,
  shareId: null,
  projectId: null,
  drawingId: null,
  systemHeight: null,
  systemWidth: null,
  isLoadingCost: true,
  isLoadingModel: true,
  projectCost: null,
  product: null,
  sideId: null,
  glazingHeight: null,
  currentSection: 1,
  currentTemplate: 1,
  glassOptionsUpper: [],
  glassOptionsLower: [],
  locksOptionsLeft: [],
  locksOptionsMiddle: [],
  locksOptionsRight: [],
  vedinOptionsLeft: [],
  vedinOptionsMiddle: [],
  vedinOptionsRight: [],
  colorOptions: [],
  hasChanged: false,
  hasLoadedOptions: false,
  control3D: false,
  iframeWidth: 0,
  iframeHeight: 0,
};

export default initialState;
