/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import useProjectData from '../../providers/ProjectDataProvider/use';
import {
  MIN_LEAFS,
  MAX_LEAFS
} from '../../constants/validations';

const LeafField = ({ name, label, initialValue, units, sendRequest }) => {
  const { projectData } = useProjectData();
  const { t } = useTranslation('common');
  const [errorList, setErrorList] = useState([]);

  const validate = (newValue) => {
    if (newValue < MIN_LEAFS || newValue.length === 0) {
      setErrorList([t('warnings.less_than', { number: MIN_LEAFS, units })]);
      return false;
    }
    if (newValue > MAX_LEAFS) {
      setErrorList([t('warnings.more_than', { number: MAX_LEAFS, units })]);
      return false;
    }
    setErrorList([]);
    return true;
  };
  
  const handleOnChange = (newValue) => {
    if(newValue !== projectData.product.parameters[name] && validate(newValue) )
      sendRequest(newValue, name);
  } 

  return(
    <NumberInput
      name={name}
      label={label}
      value={initialValue}
      errors={errorList}
      handleOnChange={handleOnChange}
      units={units}
    />
  );
};

export default LeafField;
