import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import Constants from '../constants';
import useProjectData from '../providers/ProjectDataProvider/use';

const CieloModel = ({ sharedLinkCode, loading }) => {
  const { projectData } = useProjectData();
  const [loc, setLoc] = useState(Constants.BASE_LOC);

  useEffect(() => {
    if (projectData.iframeWidth === 0 || projectData.systemWidth === null) return;
    const calcLocWidth = (1500 / projectData.systemWidth) + ( projectData.systemWidth / projectData.iframeWidth) + (projectData.systemWidth <= 1250 ? 2 : 0);
    const calcLocHeight = ( projectData.systemHeight / ( 3 * projectData.iframeHeight) );
    setLoc(calcLocWidth + calcLocHeight);
  }, [projectData.iframeWidth, projectData.iframeHeight, projectData.systemWidth, projectData.systemHeight]);

  return(
    <>
      {loading && (
        <div
          style={{ width: 'fit-content', margin: '0 auto', marginTop: '300px' }}
        >
          <ReactLoading
            type={Constants.LOADING_TYPE}
            color={Constants.MAIN_COLOR}
          />
        </div>
      )}
      {!loading && (
        <iframe
          id="cielo-iframe"
          src={`${process.env.REACT_APP_IFRAME_URL}3d.php?s=${sharedLinkCode}&loc=0.00%2C0.00%2C${loc}&look=0.00%2C0.00%2C0.00&header=0&controls=0&visualization_options=0&lang=fi`}
          title="Generated 3D Balcony Model"
        />
      )}
    </>
  )
};

export default CieloModel;
